import api from "../../components/api";
import {WebService} from "../../components/WebService";
import Swal from "sweetalert2";
import {dangerColor, infoColor, successColor, warningColor} from "../../assets/jss/material-dashboard-pro-react";

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
});

const getTasks = async () => {
  const {data} = await api.get('/task');
  return data;
};

const getTask = async id => {
  const {data} = await api.get(`/task/${id}`);
  return data;
};

const updateTask = async (id, params) => {
  await api.put(`/task/${id}`, params);
  Toast.fire({icon: 'success', title: 'Tarefa atualizada!'});
  return getTask(id);
};

const removeTask = async id => {
  const {value} = await Swal.fire({
    icon: "question",
    title: 'Deseja realmente remover?',
    showConfirmButton: true,
    showCancelButton: true,
    confirmButtonText: 'Sim',
    cancelButtonText: 'Não'
  });
  if (!value) return;
  await api.delete(`/task/${id}`);
}

const addComment = async (id, text) => {
  const {data} = await api.post(`/task/${id}/comment`, {text});

  Toast.fire({icon: 'success', title: 'Comentario adicionado com sucesso!'});
};

const createTaskItem = async id => {
  const {data} = await api.post('/task', {title: 'Item para verificação', task: {id}});
  return data;
};

const addRecipient = async (id, users) => {
  const usersTemp = {};
  users.forEach(user => {
    usersTemp[user.id_usuario] = user.nome;
  });
  const {value} = await Swal.fire({
    input: 'select',
    inputOptions: usersTemp,
    inputPlaceholder: 'Selecione o usuário',
    // inputValue: conexaoData.uuid,
    showCancelButton: true,
    cancelButtonText: 'Cancelar',
    allowOutsideClick: false,
    inputValidator: value => value ? "" : "Selecione um usuario para atribuir a tarefa"
  });
  if (!value) return;

  return updateTask(id, {user: {id: value}});
};

const removeUser = async id => {
  const {value} = await Swal.fire({
    icon: "question",
    title: 'Deseja realmente remover o usuário responsável?',
    showConfirmButton: true,
    showCancelButton: true,
    confirmButtonText: 'Sim',
    cancelButtonText: 'Não'
  });
  if (!value) return;
  await api.delete(`/task/${id}/user`);
  return true;
};

const removeTaskCalled = async (idTask, idCalled) => {
  const {value} = await Swal.fire({
    icon: "question",
    title: 'Deseja realmente remover o chamado?',
    showConfirmButton: true,
    showCancelButton: true,
    confirmButtonText: 'Sim',
    cancelButtonText: 'Não'
  });
  if (!value) return;
  await api.delete(`/task/${idTask}/called/${idCalled}`);
};

const createTask = async (idCalled = null) => {
  const {data: pipelines} = await api.get('/task/pipeline');
  const optionsPipeline = {};
  pipelines.forEach(pipeline => optionsPipeline[pipeline.id] = pipeline.name);
  const {value: pipeline} = await Swal.fire({
    icon: "question",
    title: 'Deseja criar em qual pipeline?',
    inputOptions: optionsPipeline,
    input: "select",
    confirmButtonText: 'Próximo',
    showCancelButton: true,
    cancelButtonText: 'Cancelar'
  });

  if (!pipeline) return;

  const {value: name} = await Swal.fire({
    icon: "question",
    title: 'Qual o nome da tarefa?',
    input: 'textarea',
    showCancelButton: true,
    cancelButtonText: 'Cancelar'
  });
  if (!name) return;

  const dataPost = {title: name, pipeline: {id: Number(pipeline)}};
  if (idCalled) dataPost.called = {id: idCalled}
  const {data} = await api.post('/task', dataPost);
  return data.id;
};

const TaskStatusLabel = {
  pending: {label: 'Pendente', color: 'danger', colorHex: dangerColor[0]},
  progress: {label: 'Andamento', color: 'info', colorHex: infoColor[0]},
  blocked: {label: 'Aguardando', color: 'warning', colorHex: warningColor[0]},
  finished: {label: 'Finalizada', color: 'success', colorHex: successColor[0]},
};

const TaskPriorityLabel = {
  urgent: {label: 'Urgente', color: 'danger'},
  high: {label: 'Alta', color: 'warning'},
  medium: {label: 'Média', color: 'info'},
  low: {label: 'Baixa', color: 'success'},
}

export {
  getTask,
  updateTask,
  removeTask,
  addComment,
  createTaskItem,
  addRecipient,
  removeUser,
  getTasks,
  removeTaskCalled,
  createTask,
  TaskPriorityLabel,
  TaskStatusLabel
};
export default {
  getTask,
  updateTask,
  removeTask,
  addComment,
  createTaskItem,
  addRecipient,
  removeUser,
  getTasks,
  removeTaskCalled,
  createTask,
  TaskPriorityLabel,
  TaskStatusLabel
};