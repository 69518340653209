import Swal from "sweetalert2";
import Success from "./Typography/Success";
import Danger from "./Typography/Danger";
import React from "react";
import {infoColor} from "../assets/jss/material-dashboard-pro-react";
import {apiURL, configHost, WebService} from "./WebService";
import defaultAvatar from "../assets/img/default-avatar.png";
import dataEmoji from 'emoji-mart/data/all.json';
import {getEmojiDataFromNative, Emoji} from 'emoji-mart';
import whatsappIcon from '../assets/img/icons/whatsapp.webp';
import telegramIcon from '../assets/img/icons/telegram.webp';
import axios from "axios";
import api, { baseURLV2 } from "./api";
import {isToday} from "date-fns";

export function zeroEsquerda(numero, casas = 2) {
  numero = parseInt(numero || 0, 10);
  casas = (casas - 1);
  if (numero < Math.pow(10, casas)) {
    numero = new Array(casas).fill("0").join("") + numero;
  }
  return numero.toString();
}

export function pontuacaoNumero(valor) {
  let valorFinal = "";
  valor = (valor || "0").toString().split("").reverse().join("");
  for (let i = valor.length - 1; i >= 0; i--) {
    valorFinal += valor[i];

    if (((i % 3) === 0) && (i > 0)) {
      valorFinal += ".";
    }
  }
  return valorFinal;
}

export const somenteNumero = numero => numero.toString().split("").filter(el => !isNaN(parseInt(el))).join("");

export function nomeProprio(nome) {
  if (!nome)
    return "";
  nome = nome.toLowerCase().split(" ");
  for (let i = 0; i < nome.length; i++) {
    if (nome[i].length > 3 || i === 0)
      nome[i] = nome[i][0].toUpperCase() + nome[i].slice(1).toLowerCase();
  }
  nome = nome.join(" ");
  return nome;
}

export function formatoDinheiro(valor) {
  const sinalDinheiro = "R$";
  if (!valor && valor !== 0) return `${sinalDinheiro} 0,00`;
  valor = parseFloat(valor).toFixed(2).toString().split(".");
  return `${sinalDinheiro} ${pontuacaoNumero(valor[0]) + "," + valor[1]}`;
}

/**
 * @return {boolean}
 */
function validaCPF(cpf) {
  cpf = somenteNumero(cpf);
  let soma = 0;
  let resto;
  if (cpf === "00000000000") return false;

  for (let i = 1; i <= 9; i++) soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
  resto = (soma * 10) % 11;

  if ((resto === 10) || (resto === 11)) resto = 0;
  if (resto !== parseInt(cpf.substring(9, 10))) return false;

  soma = 0;
  for (let i = 1; i <= 10; i++) soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
  resto = (soma * 10) % 11;

  if ((resto === 10) || (resto === 11)) resto = 0;
  return !(resto !== parseInt(cpf.substring(10, 11)));
}

export const validacoes = {
  nome: nome => nome ? nome.trim().split(" ").length > 1 : false,
  email: email => (/^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(email)),
  whatsapp: async whatsapp => (await (await fetch(`https://api.comunicacao.nervusw.com.br/v1/whatsapp_api/getDadosContato?numero=${whatsapp}`)).json()).ok,
  cpf: validaCPF,
  cartaoCVV: cvv => ([3, 4].indexOf(cvv.toString().length) > -1),
  cartaoNumero: numero => (somenteNumero(numero).length >= 13)
};

export const buscarEnderecoCEP = async cep => {
  Swal.fire({title: 'Buscando endereço.', allowOutsideClick: false, showConfirmButton: false});
  Swal.showLoading();
  try {
    const baseURL = `https://api.pagar.me/1/zipcodes/`;
    const {errors, zipcode, street, neighborhood, city, state} = await (await fetch(`${baseURL}${cep}`)).json();
    Swal.close();
    Swal.close();
    return {errors, zipcode, street, neighborhood, city, state};
  } catch (e) {
    Swal.close();
  }
};

export const getBandeiraCartaoImagem = bandeira => {
  bandeira = bandeira === "amex" ? "americanexpress" : bandeira;
  const baseURL = `https://app.myfinance.com.br/assets/logos/logo-creditcard`;
  return bandeira && (bandeira !== "unknown") && `${baseURL}-${bandeira}.png`;
};

export const dadosInputsCartao = {
  meses: [
    {label: "MM", disabled: true, selected: true},
    ...new Array(12).fill(null).map((prop, key) => {
      const valor = key + 1;
      return {label: zeroEsquerda(valor), value: valor};
    })
  ],
  anos: [
    {label: "ANO", disabled: true, selected: true},
    ...new Array(20).fill(null).map((prop, key) => {
      const valor = key + new Date().getFullYear();
      return {label: valor, value: valor};
    })
  ],
  parcelas: (valor, totalParcelas = 12, taxas = []) => {
    return new Array(totalParcelas).fill(null).map((prop, key) => {
      const parcela = key + 1;
      const taxa = (taxas[key] || 0) / 100 * valor;
      return {
        label: `${parcela}x de ${formatoDinheiro((valor + taxa) / parcela)}`,
        value: parcela
      };
    })
  }
};

export const copyToClipboard = text => {
  const textField = document.createElement('textarea');
  textField.value = text;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand('copy');
  document.body.removeChild(textField);
};

export const ls = slug => localStorage.getItem(slug) || "";

export const buscarDadosCNPJ = async (cnpj, loading = true, mostrarErro = true) => {
  if (loading) {
    Swal.fire({title: 'Buscando dados CNPJ', icon: 'info', allowOutsideClick: false, showConfirmButton: false});
    await Swal.showLoading();
  }
  const dados = await fetch(`https://api.nervusw.com.br/cnpj/getEmpresaCNPJ?cnpj=${cnpj}`);
  await Swal.close();
  const empresa = await dados.json();
  const {ok, mensagem_usuario, descricao_usuario} = empresa;
  if (!ok && mostrarErro) {
    await Swal.fire({icon: "warning", title: mensagem_usuario, text: descricao_usuario});
  }
  return empresa;
};

export const dataHumano = (inputData) => {
  if (inputData === "" || !inputData)
    return "";
  const z = zeroEsquerda;
  inputData = new Date(inputData)
  const [data, horas] = inputData.toISOString().split("T");
  const [ano, mes, dia] = data.split("-");
  const [hora, minuto] = horas;
  return `${dia}/${mes}/${ano} ${z(hora)}:${z(minuto)}`;
};

export function convertDate(inputFormat, sqlformat = false, datetime = false, onlyTime = false, dateDiffDay = false) {
  if (inputFormat === "" || !inputFormat)
    return "";

  function pad(s) {
    return (s < 10) ? '0' + s : s;
  }

  var d = new Date(inputFormat);
  var time = "";

  if (dateDiffDay && isToday(d)) onlyTime = true;

  if (datetime || onlyTime)
    time = " " + [pad(d.getHours()), pad(d.getMinutes())].join(":");
  if (onlyTime)
    return time;
  if (sqlformat)
    return [d.getFullYear(), pad(d.getMonth() + 1), pad(d.getDate())].join('-') + time;
  else
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/') + time;
}

export const fetchAsBlob = url => fetch(url)
  .then(response => response.blob());

export const convertBlobToBase64 = blob => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.onerror = reject;
  reader.onload = () => resolve(reader.result);
  reader.readAsDataURL(blob);
});

export const isValidDate = d => d instanceof Date && !isNaN(d);

export const undefinedFunction = a => a;

export const downloadFileAsBlob = (blob, filename) => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  a.remove();
};

export const formataCPFCNPJ = valor => {
  if (!valor)
    return "";
  valor = somenteNumero(valor).toString();
  if (valor.length === 11)
    return valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
  else if (valor.length === 14)
    return valor.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "$1.$2.$3/$4-$5");
  return valor;
};


export const getColoredNumber = (number, invertido = false) => {
  number = parseInt(number);
  const Component = invertido ? Danger : Success;
  const ComponentNegativo = invertido ? Success : Danger;

  if (number > 0)
    return <Component><strong>{number}</strong></Component>;
  if (number < 0)
    return <ComponentNegativo><strong>{number}</strong></ComponentNegativo>;
  return number;
};

export const sairSistema = () => {
  const dadosAnterior = JSON.parse(localStorage.getItem('dadosAnterior'));

  localStorage.setItem('token', '');
  localStorage.removeItem('PARTNER_TOKEN');
  if (dadosAnterior) {
    const localConfigHost = localStorage.getItem('localConfigHost');
    localStorage.clear();
    localStorage.setItem('localConfigHost', localConfigHost);
    const keysDados = Object.keys(dadosAnterior);
    for (let i = 0; i < keysDados.length; i++) {
      localStorage.setItem(keysDados[i], dadosAnterior[keysDados[i]]);
    }
  }

  window.location.href = '/dashboard';
};

export const Filter = ({filter, onChange}, fn = a => a) => {
  if (typeof fn !== "function")
    fn = a => a;

  return (
    <input
      onChange={({target}) => onChange(fn(target.value))}
      value={filter ? filter.value : ''}
      placeholder={"Pesquisar"}
      style={{
        width: '100%',
        backgroundImage: `linear-gradient(${infoColor[0]}, ${infoColor[4]}), linear-gradient(#d2d2d2, #d2d2d2)`
      }}
    />
  );
};

export const FilterSelect = ({filter, onChange}, fn = a => a, opcoes = []) => {
  if (typeof fn !== "function")
    fn = a => a;

  return (
    <select
      onChange={({target}) => onChange(fn(target.value))}
      style={{
        width: '100%',
        color: '#555',
        fontSize: 14,
        padding: '5px 0',
        backgroundImage: `linear-gradient(${infoColor[0]}, ${infoColor[4]}), linear-gradient(#d2d2d2, #d2d2d2)`
      }}
    >
      {opcoes.map((prop, key) => (
        <option value={prop} key={key}>
          {nomeProprio(prop)}
        </option>
      ))}
    </select>
  );

  return (
    <input
      onChange={({target}) => onChange(fn(target.value))}
      value={filter ? filter.value : ''}
      placeholder={"Pesquisar"}
      style={{
        width: '100%',
        backgroundImage: `linear-gradient(${infoColor[0]}, ${infoColor[4]}), linear-gradient(#d2d2d2, #d2d2d2)`
      }}
    />
  );
};

export const alterarSenhaUsuario = async (outros = {}, validado = true) => {
  do {
    const {value: senha} = await Swal.fire({
      title: 'Nova Senha',
      input: 'password',
      inputPlaceholder: 'Nova Senha',
      inputAttributes: {maxlength: 20, autocapitalize: 'off', autocorrect: 'off'}
    });

    if (senha) {
      const {value: passwordConfirm} = await Swal.fire({
        title: 'Confirme a nova Senha',
        input: 'password',
        inputPlaceholder: 'Confirme a nova Senha',
        inputAttributes: {maxlength: 20, autocapitalize: 'off', autocorrect: 'off'}
      });
      if (senha.length >= 6) {
        if (senha === passwordConfirm) {
          const {ok} = await WebService("/usuario/alterarSenha", {senha, ...outros}, "POST");
          if (ok) {
            await Swal.fire({icon: "success", title: "Senha alterada com sucesso!"});
            validado = true;
          }
        } else
          await Swal.fire({icon: "warning", title: "As senhas não correspondem."});
      } else
        await Swal.fire({icon: "warning", title: "As senhas devem conter no mínimo 6 caracteres."});
    } else
      await Swal.fire({icon: "warning", title: "A senha não é válida."});
  } while (!validado);
};

export const tiposNotas = {55: 'NF-e', 57: 'CT-e', 65: 'NFC-e', 99: 'NFS-e'};

export const converterTextoWpp = (text, line = false) => {
  if (!text) return "";
  text = text.trim();
  text = text.replace(/\*(.*?)\*/gm, '<strong>$1</strong>');
  text = text.replace(/\_(.*?)\_/gm, '<i>$1</i>');
  text = text.replace(/\~(.*?)\~/gm, '<text style="text-decoration: line-through;">$1</text>');
  text = text.replace(/\`\`\`(.*?)\`\`\`/gm, '<text style="font-family:\'Lucida Console\', monospace">$1</text>');
  if (line) text = text.split('\n').join('<br/>')
  if (line) text = text.split('\\n').join('<br/>')
  return text;
};

export const removeAttendanceNameFromMessage = (message, attendanceName) => {
  if (!attendanceName || !message) return message;

  console.log({message, attendanceName});
  const hasName = message.includes(`${attendanceName}*:\n`);
  if (hasName) return message.split(`${attendanceName}*:\n`)[1].trim();

  return message;
};

// export const getFotoLink = foto_perfil => foto_perfil ? `http${configHost.protocol}://${configHost.baseURL}${configHost.portHttp}${configHost.versionAPI}/chat/getFotoPerfil?p=${foto_perfil}` : defaultAvatar;
export const getFotoLink = foto_perfil => foto_perfil ? `https://storage.hustapp.com/foto_perfil/${foto_perfil}` : defaultAvatar;

export const traducaoReactTable = {
  previousText: "<",
  nextText: ">",
  loadingText: "Carregando...",
  pageText: "Página",
  ofText: "de",
  noDataText: "Nenhum registro encontrado",
  rowsText: "registros"
};

export const mesesAno = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"];

export const iconMessage = icon => {
  const whatsapp = whatsappIcon;
  const telegram = telegramIcon;
  const icons = {whatsapp, telegram};
  return icons[icon];
};

export const formataNumeroTelefone = (numero) => {
  var telefoneFormatado;
  //está desconsiderado o digito 9 ~ não acompanha no id_whatsapp
  if (numero.length === 12 && numero.substring(0, 2) === '55') {
    // (99) 9999-9999
    telefoneFormatado = '(' + numero.substring(2, 4) + ') ' + '9 ' + numero.substring(4, 8) + '-' + numero.substring(8, 12);
  } else {
    telefoneFormatado = '+' + numero;
  }

  return telefoneFormatado;
}

export const traducaoTable = {
  textLabels: {
    body: {
      noMatch: "Nenhum registro foi encontrado",
      toolTip: "Ordenar",
      columnHeaderTooltip: column => `Ordenar por ${column.label}`
    },
    pagination: {
      next: "Próxima página",
      previous: "Página anterior",
      rowsPerPage: "Registros por página:",
      displayRows: "de",
    },
    toolbar: {
      search: "Pesquisar",
      downloadCsv: "Baixar CSV",
      print: "Imprimir",
      viewColumns: "View Columns",
      filterTable: "Filtrar",
    },
    filter: {all: "Todos", title: "FILTERS", reset: "RESET",},
    viewColumns: {title: "Mostrar colunas", titleAria: "Mostrar/Esconder colunas",},
    selectedRows: {text: "Linha(s) selecionadas", delete: "Excluir", deleteAria: "Excluir linhas selecionadas",},
  }
};

export const base64ToBlob = base64 => {
  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i += 1)
    byteNumbers[i] = byteCharacters.charCodeAt(i);

  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray]);
};

export const uploadFile = async (fileName, fileBase64, onUploadProgress) => {
  const contentType = fileBase64.split(';')[0].split(':')[1];

  // Tem que converter o arquivo p blob de novo 🤦🏻
  const fileBlob = base64ToBlob(fileBase64.split('base64,')[1]);

  const {data: {signedUrl, uuid, filePath}} = await api.get(`/file/signature`, {params: {fileName, contentType}});
  const headers = {'Content-Type': contentType, 'Content-Disposition': `attachment; filename=${encodeURI(fileName)}`};
  console.log({signedUrl, fileBlob, headers});
  await axios.put(signedUrl, fileBlob, {
    headers,
    onUploadProgress: ({progress}) => onUploadProgress(progress * 100)
  });

  return {uuid, filePath};
};

export const getIAPromtResponseStream = async (path, body, onData) => {
  const response = await fetch(`${baseURLV2}${path}`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'text/event-stream',
      token: localStorage.getItem('token'),
      authorization: `Bearer ${localStorage.getItem('token')}`
    },
  });

  const reader = response.body.getReader();

  while (true) {
    const { done, value } = await reader.read();
    if (done) {
      break;
    }

    const streamData = new TextDecoder().decode(value);
    try {
      const [ , json ] = streamData.split('data: ');
      const data = JSON.parse(json);
      onData((data.text || '').trim());
    } catch (e) {

    }
  }
};

export const isLocalhost = ['127.0.0.1', 'localhost'].includes(window.location.hostname);
export const isHustDomain = window.location.host.endsWith('hust.chat');