import axios from "axios";
import Swal from "sweetalert2";
import { checkBeta } from "../views/BetaControll";

// export const baseURLV2 = checkBeta('new-api-endpoint') ? `https://api.hustapp.com/v2` : `https://api.macrochat.com.br/v2`;
export const baseURLV2 = checkBeta("new-api-endpoint")
  ? `https://api.hustapp.com/v2`
  : `https://api.hustapp.com/v2`;
const api = axios.create({ baseURL: baseURLV2 });

let timeout;
api.interceptors.request.use((req) => {
  // timeout = setTimeout(() => {
  //   Swal.fire({title: "Carregando", allowOutsideClick: false});
  //   Swal.showLoading();
  // }, 800);
  const token  = localStorage.getItem('token') || localStorage.getItem('PARTNER_TOKEN');
  req.headers.token = token;
  req.headers.authorization = `Bearer ${token}`;
  req.headers["app-version"] = "1.0.6";
  return req;
});

api.interceptors.response.use(
  (res) => {
    clearTimeout(timeout);
    Swal.close();
    // TODO - Tratar erros
    return res;
  },
  async (e) => {
    clearTimeout(timeout);
    Swal.close();

    const { message, userMessage, user_message } = e?.response?.data;
    const title = userMessage || "Ocorreu um erro na requisição.";
    const text = message;

    if (['INVALID_SESSION', 'SESSION_EXPIRED'].includes(user_message)) {
      await Swal.fire({
        title: `Usuário desconectado.`,
        text: `Por favor, faça login e tente novamente.`,
        icon: "warning",
      });
      const localConfigHost = localStorage.getItem("localConfigHost");
      localStorage.clear();
      localStorage.setItem("localConfigHost", localConfigHost);
      window.location.href = "/";
    } else Swal.fire({ icon: "warning", title, text });
    throw e;
  }
);

export default api;
