import React from 'react';
import {Close, MessageOutlined} from "@material-ui/icons";
import Button from '../../../components/CustomButtons/Button';
import Swal from "sweetalert2";
import {useStoreApi} from "reactflow";
import api from "../../../components/api";

const RemoveNode = ({id}) => {
  const store = useStoreApi();

  const deleteNode = async () => {
    const state = store.getState();
    const nodes = state.getNodes();
    const {edges} = state;

    const currentNode = nodes.find(el => el.id === id);
    if (currentNode.type === 'start' && currentNode.data.id) {
      try {
        await api.delete(`/bot/${currentNode.data.id}`);
      } catch (e) {
        if (!e?.response?.data?.includes('BOT_NOT_FOUND')) throw e;
      }
      delete nodes[nodes.map(prop => prop.id).indexOf(id)];
      await api.put(`/bot/raw`, {dataRaw: {nodes: nodes.filter(el => el), edges}});
    }

    state.onNodesDelete(id);
    state.nodeInternals.delete(id);

    store.setState(state);
  };

  return (
    <Button
      round
      simple
      color={'danger'}
      style={{
        zIndex: 99999,
        position: 'absolute',
        right: 0,
        top: 0,
        width: 30,
        height: 30,
        minWidth: 0
      }}
      onClick={async () => {
        const isConnected = !!store.getState().edges.filter(el => el.target === id || el.source === id).length;
        if (isConnected) {
          return Swal.fire({
            icon: "warning",
            title: 'Não é possível excluir pois existe mais de uma ligação ativa.',
            text: 'Remova todas as ligações e tente novamente.'
          });
        }

        const {value} = await Swal.fire({
          icon: "question",
          title: 'Deseja realmente excluir?',
          showCancelButton: true,
          cancelButtonText: 'Não',
          confirmButtonText: 'Sim'
        });
        if (!value) return;

        deleteNode();
      }}
      justIcon
    >
      <Close/>
    </Button>
  );
}

export default RemoveNode;