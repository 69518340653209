import React, {useEffect, useState} from 'react';
import {Badge, Card, CardBody, CardHeader, GridContainer, GridItem, Table} from "../../components";
import api from "../../components/api";
import {convertDate, copyToClipboard, getFotoLink} from "../../components/Funcoes";
import {FormControlLabel, Tooltip} from "@material-ui/core";
import Button from "../../components/CustomButtons/Button";
import Danger from "../../components/Typography/Danger";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import styles from "../../assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import {makeStyles} from "@material-ui/core/styles";
import {FaEye, FaFilter, FaRegEdit} from "react-icons/fa";
import * as Swal from "sweetalert2";
import Build from "@material-ui/icons/Build";
import CustomDropdown from "../../components/CustomDropdown/CustomDropdown";
import TaskViewer from "../Modal/Task/TaskViewer";
import {dangerColor, infoColor, successColor, warningColor} from "../../assets/jss/material-dashboard-pro-react";
import ViewUserActions from "./components/ViewUserActions";
import {removeTask, removeUser, TaskPriorityLabel, TaskStatusLabel} from "./taskHelper";
import {IoClose} from "react-icons/io5";
import ModalCalledHistory from "../Modal/CalledHistory";


const useStyles = makeStyles(styles);

const TaskManager = ({tasks, getTasks, openTask, changeTask, changeTaskStage, stages}) => {
  const classes = useStyles();

  // const [tasks, setTasks] = useState([]);
  // const [openTaskID, setOpenTaskID] = useState(null);
  const [openChatId, setOpenChatId] = useState(null);

  const [filterOnlyMe, setFilterOnlyMe] = useState(false);
  const [filter, setFilter] = useState(['pending', 'progress', 'blocked']);

  // const getTasks = async () => {
  //   const {data} = await api.get('/task');
  //   setTasks(data);
  // };

  const createTask = async () => {
    // const {value} = await Swal.fire({icon: "question", title: 'Qual o nome da tarefa?', input: 'textarea', showCancelButton: true, cancelButtonText: 'Cancelar'});
    // if (!value) return;
    //
    // await api.post('/task', {title: value});
    // await getTasks();
  };

  // const changeTask = async (id, params) => {
  //   await api.put(`/task/${id}`, params);
  //   setTasks(task => {
  //     const keyTask = task.map(prop => prop.id).indexOf(id);
  //     task[keyTask] = {...task[keyTask], ...params};
  //     return [...task];
  //   });
  //   Swal.mixin({toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, timerProgressBar: true})
  //     .fire({icon: 'success', title: 'Tarefa alterada com sucesso!'});
  // };
  //
  // useEffect(() => {
  //   getTasks();
  // }, []);

  return (
    <GridContainer>
      <GridItem lg={12} md={12} sm={12} xs={12}>
        <Card>
          <CardHeader>
              <CustomDropdown
                buttonIcon={FaFilter}
                // innerDropDown={true}
                // caret={false}
                buttonProps={{justIcon: true, color: 'primary'}}
                dropdownList={[
                  {label: 'Todos', value: 'all', checkbox: true},
                  // {'data-ref': 'multi'},
                  {divider: true},
                  ...Object.keys(TaskStatusLabel).map(prop => ({value: prop, label: TaskStatusLabel[prop].label, checkbox: true})),
                  {divider: true},
                  {label: 'Tarefas de hoje', value: 'task_date_today', checkbox: true},
                  {label: 'Data de Vencimento passado', value: 'task_date_overdue', checkbox: true},
                  {label: 'Próximas Tarefas', value: 'task_date_future', checkbox: true},
                  {divider: true},
                  {label: 'Tarefas atribuidas a mim', value: 'my_task', checkbox: true},
                  {label: 'Não Atribuido', value: 'not_assigned', checkbox: true},
                  // { divider: true },
                  // 'Por membro',
                ].map(prop => ({...prop, selected: filter.includes(prop.value)}))}
                // noLiPadding={true}
                onClick={value => {
                  if (value === 'all') {
                    setFilter(['all']);
                    return;
                  }
                  setFilter(filter => {
                    if (filter.includes(value)) delete filter[filter.indexOf(value)];
                    else filter.push(value);

                    return [...filter].filter(el => el && el !== 'all');
                  });
                }}
              />
          </CardHeader>
          <CardBody>
            <Table
              striped
              hover
              tableHead={['#', 'Título', 'Status', 'Início', 'vencimento', 'Responsável', 'Chamados', 'Prioridade', '']}
              tableData={tasks.filter(el => {
                let show = false;
                if (filter.includes('all')) return true;

                // Filtrando status
                if (filter.includes('pending') && el.status === 'pending') return true;
                if (filter.includes('progress') && el.status === 'progress') return true;
                if (filter.includes('blocked') && el.status === 'blocked') return true;
                if (filter.includes('finished') && el.status === 'finished') return true;

                // Filtros de data
                if (filter.includes('task_date_today') && new Date(el.dateDue).toISOString().split('T')[0] === new Date().toISOString().split('T')[0]) return true;
                if (filter.includes('task_date_overdue') && new Date(el.dateDue).getTime() < new Date().getTime()) return true;
                if (filter.includes('task_date_future') && new Date(el.dateDue).getTime() >= new Date().getTime()) return true;

                // Filtro de responsável
                if (filter.includes('my_task') && el?.user?.id === props?.usuario?.id_usuario) return true;
                if (filter.includes('not_assigned') && !el.user) return true;

                return false;
              }).map((prop, keyTask) => {
                const stage = stages.find(el => el.tasks.find(task => task.id === prop.id));
                console.log({prop});
                return [
                  prop.id,
                  prop.title,
                  // (<Badge color={TaskStatusLabel[prop.status].color}>{TaskStatusLabel[prop.status].label}</Badge>),
                  (
                    <CustomDropdown
                      // buttonText={TaskStatusLabel[prop.status].label}
                      buttonText={stage.name}
                      buttonProps={{
                        round: true,
                        style: {
                          marginBottom: "0",
                          height: 24,
                          width: 120,
                          border: `1px solid ${stage.color}`,
                          background: 'none',
                          color: stage.color
                        },
                        color: stage.color
                      }}
                      dropdownList={stages.sort((a, b) => a.sequence > b.sequence ? 1 : (a.sequence < b.sequence ? -1 : 0)).map(prop => ({
                        value: prop.id,
                        label: prop.name
                      }))}
                      noLiPadding={true}
                      onClick={stage => changeTaskStage(Number(prop.id), Number(stage))}
                    />
                  ),
                  convertDate(prop.dateStart),
                  new Date(prop.dateDue).getTime() < new Date().getTime() ?
                    <Danger><strong>{convertDate(prop.dateDue)}</strong></Danger> : convertDate(prop.dateDue),
                  prop.user ? (
                    <ViewUserActions
                      name={prop.user.name}
                      profilePicture={prop.user.profilePicture}
                      showRemove
                      onDelete={async () => {
                        if (await removeUser(prop.id)) {
                          getTasks();
                        }
                      }}
                    />
                  ) : '',
                  // prop?.department?.name || '',
                  <div style={{display: 'flex'}}>
                    {prop?.calleds?.map((called, k) => {
                      const {contact: {name, idWhatsapp}} = called;
                      const [phone] = idWhatsapp.split('@');
                      return (
                        <ViewUserActions
                          onClick={() => setOpenChatId(called.id)}
                          name={called.id + ' - ' + (name || phone)}
                          profilePicture={called.contact.profilePicture}
                          showRemove
                          onDelete={() => ''}
                          multiple={!!k}
                        />
                      );
                    })}
                  </div>,
                  (
                    <CustomDropdown
                      buttonText={TaskPriorityLabel[prop.priority].label}
                      buttonProps={{
                        simple: true,
                        round: true,
                        style: {marginBottom: "0", height: 24, width: 90},
                        color: TaskPriorityLabel[prop.priority].color
                      }}
                      dropdownList={Object.keys(TaskPriorityLabel).map(prop => ({
                        value: prop,
                        label: TaskPriorityLabel[prop].label
                      }))}
                      noLiPadding={true}
                      onClick={priority => changeTask(prop.id, {priority})}
                    />
                  ),
                  (
                    <>
                      <Tooltip title={'Abrir tarefa'}>
                        <Button
                          style={{margin: 0, width: 30, height: 30, minWidth: 30, padding: 0}}
                          justIcon round simple color={'info'} onClick={() => openTask(prop.id)}
                          size={'sm'}
                        >
                          <FaRegEdit/>
                        </Button>
                      </Tooltip>
                      <Tooltip title={'Excluir tarefa'}>
                        <Button
                          style={{margin: 0, width: 30, height: 30, minWidth: 30, padding: 0}}
                          justIcon round simple color={'danger'} onClick={() => removeTask(prop.id).then(getTasks)}
                          size={'sm'}
                        >
                          <IoClose/>
                        </Button>
                      </Tooltip>
                    </>
                  )
                ];
              })}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default TaskManager;